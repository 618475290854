import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import {
  BooleanField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import BackButton from "../components/BackButton";
import { Account, NotificationTypes } from "../react-admin-loopback/api/api";
import { LoopbackAPI } from "../react-admin-loopback/api/loopback-api";
import { AccountType } from "../react-admin-loopback/api/shared-constants";

const useStyles = makeStyles({
  metaDataHeader: { width: "250px" },
});

const InAppNotificationFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <ReferenceInput
        source="accountId"
        perPage={null}
        reference="Accounts"
        sort={{ field: "nom", order: "ASC" }}
        alwaysOn
        filter={{
          type: {
            inq: [
              AccountType.CONDUCTEUR,
              AccountType.EXTERNE,
              AccountType.PERSONNEL,
            ],
          },
        }}
      >
        <SelectInput optionText="nom" />
        {/*<AutocompleteInput optionText="nom"/>*/}
      </ReferenceInput>
      {/*<SelectInput source="date"  alwaysOn/>*/}
    </Filter>
  );
};

export const CustomForm = (props) => {
  const [notificationTypes, setNotificationTypes] = useState<
    NotificationTypes[]
  >([]);
  const [conducteurs, setConducteurs] = useState<Account[]>([]);
  const API = new LoopbackAPI();

  useEffect(() => {
    async function getData() {
      const notifs = await API.notificationTypes.notificationTypesFind();
      setNotificationTypes(notifs);
    }
    getData();
  }, []);

  useEffect(() => {
    async function getData() {
      const filter = {
        where: {
          type: {
            inq: [
              AccountType.CONDUCTEUR,
              AccountType.EXTERNE,
              AccountType.PERSONNEL,
            ],
          },
        },
        fields: ["id", "nom"],
        order: "nom ASC",
      };
      console.log(filter);
      const conducteurs = await API.accounts.accountFind({
        filter: JSON.stringify(filter),
      });
      setConducteurs(conducteurs);
    }
    getData();
  }, []);
  const translate = useTranslate();
  return (
    <div className="w-100">
      <div className="d-flex flex-wrap">
        <div className="col-12 col-sm-6">
          <SelectInput
            source="accountId"
            choices={conducteurs}
            optionText="nom"
            label={translate("commons.driver")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <SelectInput
            source="notificationTypeId"
            choices={notificationTypes}
            optionText="nom"
            label={translate("commons.messageType")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="message"
            label={translate("commons.message")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="nomNotification"
            label={translate("commons.nomNotification")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="resumeNotification"
            label={translate("commons.resumeNotification")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6" style={{ visibility: "hidden" }}>
          <DateTimeInput
            source="dateNotification"
            label={translate("commons.dateNotification")}
            initialValue={new Date()}
            disable={true}
            fullWidth
          />
        </div>
      </div>
    </div>
  );
};

const CustomMetaDataField = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.metaDataHeader}>
      {props?.record?.metaData &&
        JSON.stringify(props?.record?.metaData, null, 4)}
    </div>
  );
};
export const InAppNotificationList = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <List {...props} filters={<InAppNotificationFilter />} exporter={false}>
      <Datagrid>
        <TextField source="id" />
        <ReferenceField source="accountId" reference="Accounts">
          <TextField source="nom" label={translate("commons.driver")} />
        </ReferenceField>
        <TextField
          source="messageType"
          label={translate("commons.messageType")}
        />
        <ReferenceField
          source="notificationTypeId"
          reference="NotificationTypes"
          link={false}
        >
          <TextField
            source="nom"
            label={translate("commons.notificationType")}
          />
        </ReferenceField>
        <TextField source="message" label={translate("commons.message")} />
        <BooleanField source="isRead" label={translate("commons.isRead")} />
        <CustomMetaDataField
          source="metaData"
          label={translate("commons.metaData")}
        />
        <TextField
          source="nomNotification"
          label={translate("commons.nomNotification")}
        />
        <TextField
          source="resumeNotification"
          label={translate("commons.resumeNotification")}
        />
        <DateField
          source="dateNotification"
          label={translate("commons.dateNotification")}
          showTime
        />
        <DateField
          source="dateLecture"
          label={translate("commons.dateLecture")}
          showTime
        />
        <DateField
          source="heureLecture"
          label={translate("commons.heureLecture")}
          showTime
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const InAppNotificationEdit = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/RHs" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton />
        <h3>{translate("commons.editNotification")}</h3>
        <b></b>
      </div>
      <Edit {...props}>
        <SimpleForm>
          <CustomForm />
        </SimpleForm>
      </Edit>
    </div>
  );
};
export const InAppNotificationCreate = (props: any) => {
  console.log("InAppNotificationCreate", props);
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <BackButton />
        <h3>{translate("commons.newNotification")}</h3>
        <b></b>
      </div>
      <Create {...props}>
        <SimpleForm redirect="/InAppNotifications">
          <CustomForm />
        </SimpleForm>
      </Create>
    </div>
  );
};
