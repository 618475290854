import React, {useState} from "react";
import {
    Create,
    Datagrid, DateField,
    DeleteButton,
    Edit,
    Filter,
    ImageField,
    ImageInput,
    List,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useTranslate,
} from "react-admin";
import {AccountType} from "../react-admin-loopback/api/shared-constants";
import {API_URL} from "../variables/constant";
import {LoopbackAPI} from "../react-admin-loopback/api/loopback-api";
import ClearIcon from "@material-ui/icons/Clear";
import {ViewImage} from "./view-image";
import BackButton from "../components/BackButton";

const CmrFilter = (props: any) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <ReferenceInput
                source="accountId"
                sort={{field: "nom", order: "ASC"}}
                perPage= { null }
                reference="Accounts"
                label={translate("commons.name")}
                alwaysOn
            >
                <SelectInput optionText="nom"/>
            </ReferenceInput>
            <NumberInput source="etapeId"
                         reference="Etapes" alwaysOn/>
        </Filter>
    );
};
export const CmrList = (props) => {
    const translate = useTranslate();
    return (
        <List
            {...props}
            filters={<CmrFilter/>}
            filter={{and: [{ etapeId: {neq: null}}]}}
            sort={{field: "updatedAt", order: "DESC"}}
        >
            <Datagrid>
                <TextField source="id"/>
                <TextField source="cMRno" label={translate("commons.CMRno")}/>
                {/*<ImagesShowInTable source="image" headerClassName="w-30"/>*/}
                <ViewImage
                    label={translate("commons.image")}
                    source="image"
                    containerId="cmrs"
                />
                <ReferenceField
                    source="accountId"
                    reference="Accounts"
                    label={translate("commons.driver")}
                >
                    <TextField source="nom"/>
                </ReferenceField>
                <ReferenceField
                    source="etapeId"
                    reference="Etapes"
                    label={translate("commons.etape")}
                >
                    <TextField source="id"/>
                </ReferenceField>
                <ReferenceField
                    source="missionId"
                    reference="Missions"
                    label={translate("commons.mission")}
                >
                    <TextField source="ot"/>
                </ReferenceField>
                <TextField
                    source="type"
                    headerClassName="p-sm"
                    cellClassName={"p-sm"}
                />
                <DateField source="createdAt" label={translate("commons.createdAt")} showTime={true} />
                {/*<EditButton />*/}
                <DeleteButton/>
            </Datagrid>
        </List>
    );
};
const ImagesShow = (props) => {
    const [image, setImage] = useState(props.image);
    const removeImage = async () => {
        const API = new LoopbackAPI();
        setImage(null);
        // const data: CMR;
        // await API.dNs.dnPrototypePatchAttributes(props.id, {image: ''});
    };
    return image?.length > 0 ? (
        <div className="d-flex align-items-center justify-content-center">
            <div className="wrap-image">
                <img
                    src={`${API_URL}/Containers/photos/download/${props?.image}`}
                    alt=""
                    className="mr-2 custom-image"
                />
                <div className="ic-cancel" onClick={() => removeImage()}>
                    <ClearIcon/>
                </div>
            </div>
        </div>
    ) : null;
};

const ImagesShowInTable = (props) => {
    return props?.record?.image?.length > 0 ? (
        <div className="d-flex align-items-center justify-content-center">
            <img
                src={`${API_URL}/Containers/photos/download/${props?.record?.image}`}
                alt=""
                className="custom-image"
            />
        </div>
    ) : null;
};

const CustomForm = (props) => {
    console.log(props);
    const translate = useTranslate();
    return (
        <div className="w-100">
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    <ImagesShow image={props?.record?.image} id={props?.record?.id}/>
                    <ImageInput
                        source="pictures"
                        label={translate("commons.uploadImage")}
                        accept="image/*"
                    >
                        <ImageField source="src" title="image"/>
                    </ImageInput>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput
                        source="cMRno"
                        label={translate("commons.CMRno")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <ReferenceInput
                        source="accountId"
                        reference="Accounts"
                        sort={{field: "nom", order: "ASC"}}
                        filter={{type: AccountType.CONDUCTEUR}}
                        fullWidth
                    >
                        <SelectInput optionText="nom"/>
                    </ReferenceInput>
                </div>
                <div className="col-12 col-sm-6">
                    <ReferenceInput source="etapeId" reference="Etapes" fullWidth>
                        <SelectInput optionText="id"/>
                    </ReferenceInput>
                </div>
                {" "}
                <div className="col-12 col-sm-6">
                    <ReferenceInput source="missionId" reference="Missions" fullWidth>
                        <SelectInput optionText="ot"/>
                    </ReferenceInput>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput
                        source="type"
                        disabled
                    />
                </div>
            </div>
        </div>
    );
};

export const CmrCreate = (props) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/CMRs" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
                <BackButton/>
                <h3>{translate("cmr.create")}</h3>
                <b></b>
            </div>
            <Create {...props}>
                <SimpleForm redirect="/CMRs">
                    <CustomForm/>
                </SimpleForm>
            </Create>
        </div>
    );
};

const click = () => {
    console.log("toinv");
};

export const CmrEdit = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/CMRs" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
                <BackButton/>
                <h3>{translate("cmr.edit")}</h3>
                <b></b>
            </div>
            <Edit {...props}>
                <SimpleForm>
                    <CustomForm/>
                </SimpleForm>
            </Edit>
        </div>
    );
};
